<template>
  <section class="box-height perfromanceTable" style="padding: 0 15px">
    <div
      class="feedbackCheck"
      style="background-color: #fff; padding-bottom: 30px"
    >
      <div class="searchM">
        <el-form
          :model="filter"
          ref="filter"
          style="background-color: #fff"
          label-width="120px"
          class="clearfix"
        >
          <div class="clearfix sechContent" style="padding: 15px 0px 0">
            <el-form-item label="问题归属：" prop="belongTo">
              <el-select
                v-model="filter.belongTo"
                placeholder="请选择"
                clearable
                multiple
                collapse-tags
                style="width: 240px"
                @change="getData"
              >
                <el-option
                  v-for="(item, index) in belongTosList"
                  :key="'belongTo_' + index"
                  :value="item"
                  :label="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态：" prop="status">
              <el-select
                v-model="filter.status"
                placeholder="请选择"
                clearable
                multiple
                collapse-tags
                style="width: 240px"
                @change="getData"
              >
                <el-option
                  v-for="(item, index) in statusList"
                  :key="'status_' + index"
                  :value="item"
                  :label="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="创建时间：" prop="createdStartTime">
              <el-date-picker
                style="width: 400px"
                v-model="timeVal"
                value-format="yyyy-MM-dd HH:mm"
                format="yyyy-MM-dd HH:mm"
                @change="getTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="创建人：" prop="createdUserIds">
              <el-select
                v-model="filter.createdUserIds"
                placeholder="请选择"
                style="width: 240px"
                filterable
                clearable
                multiple
                size="mini"
                collapse-tags
                @change="getData"
              >
                <el-option
                  v-for="i in userList"
                  :key="i.id"
                  :label="i.name"
                  :value="i.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-pagination
              :hide-on-single-page="filter.page.total > 10 ? false : true"
              v-if="filter.page.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="filter.page.current"
              :page-size="filter.page.size"
              :pager-count="5"
              layout="total,prev, pager, next,sizes"
              :total="filter.page.total"
              :page-sizes="[10, 20, 50, 100]"
              style="float: right"
            ></el-pagination>
          </div>
        </el-form>
      </div>
      <div class="pd40" style="background-color: #fff; padding-bottom: 20px">
        <el-table
          :data="ListData"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
            'border-top': '1px #e5e5e5 solid',
          }"
          class="tabBorder custor"
          style="width: 100%; margin: 0px; border-top: 0"
        >
          <el-table-column prop="recordId" min-width="80" label="问题ID">
          </el-table-column>
          <el-table-column prop="textDesc" min-width="260" label="问题描述">
          </el-table-column>
          <el-table-column
            prop="idOrTel"
            min-width="260"
            label="云虰的重复简历ID（或联系方式）"
          >
          </el-table-column>
          <el-table-column prop="attachments" min-width="350" label="附件">
            <template slot-scope="scope">
              <p
                class="tabHref mgr10"
                v-for="(item, index) in scope.row.attachments"
                :key="'attachments' + index"
              >
                <span @click="previewOpen(item)">{{ item.fileName }} </span>
                <i
                  @click="handleDown(item)"
                  class="el-icon-download"
                  style="margin-left: 15px"
                ></i>
              </p>
              <p v-if="scope.row.attachments.length == 0">-</p>
            </template>
          </el-table-column>
          <el-table-column prop="belongTo" min-width="150" label="问题归属">
            <template slot-scope="scope">
              <span
                v-for="(item, index) in scope.row.belongTo"
                :key="index"
                style="margin-right: 10px"
                >{{ item }}</span
              >
              <span v-if="scope.row.belongTo.length == 0">-</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="resolvePlan"
            label="解决计划"
            min-width="260"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.resolvePlan ? scope.row.resolvePlan : "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="status" min-width="150" label="状态">
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.status == '待接收' ||
                  scope.row.status == '技术定位中' ||
                  scope.row.status == '解决中'
                    ? 'color:#5daf34'
                    : scope.row.status == '暂无法解决'
                    ? 'color:#FF1D1D'
                    : 'color:#999'
                "
                >{{ scope.row.status ? scope.row.status : "-" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="resolvePeriod"
            min-width="150"
            label="解决周期"
          >
            <template slot-scope="scope">
              {{ scope.row.resolvePeriod ? scope.row.resolvePeriod : "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createdUserName"
            min-width="150"
            label="创建人"
          >
          </el-table-column>
          <el-table-column prop="createdTime" min-width="150" label="创建时间">
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="left" width="180">
            <template slot-scope="scope">
              <span class="tabHref" size="mini" @click="handlerEdit(scope.row)"
                >编辑
              </span>
              <el-divider direction="vertical"></el-divider>
              <span
                class="tabHref"
                size="mini"
                @click="progressTracking(scope.row)"
                >进度跟踪</span
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="filter.page.total"
          :page-sizes="[10, 20, 50, 100]"
          style="padding: 10px 15px 20px"
        ></el-pagination>
      </div>
    </div>
    <!--抽屉-->
    <el-drawer
      :visible.sync="drawerVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      :modal="false"
      direction="ttb"
      custom-class="drawerClass"
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">{{ titName }}</div>
      <div class="drawerCon">
        <feedbackInfo
          v-if="drawerVisible && titName == '反馈查重问题'"
          ref="feedbackInfo"
          :recordId="recordId"
          @close="handleClose"
        ></feedbackInfo>
        <progressTracking
          v-if="drawerVisible && titName == '进度跟踪'"
          ref="progressTracking"
          :recordId="recordId"
          @close="handleClose"
        ></progressTracking>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import {
  resumeRepeatRecordPage, //反馈查重问题列表
  getUserBaseInfos,
} from "../api/api";
import * as api from "../api/http";
import feedbackInfo from "../components/feedback/feedbackInfo";
import progressTracking from "../components/feedback/progressTracking";
export default {
  components: { feedbackInfo, progressTracking },
  data() {
    return {
      filter: {
        belongTo: [],
        status: [],
        queryTimeStart: "",
        queryTimeEnd: "",
        createdUserIds: [],
        page: {
          current: 1,
          size: 50,
          total: 0,
        },
      },
      timeVal: "",
      belongTosList: ["云虰", "倍罗"],
      statusList: ["待接收", "技术定位中", "解决中", "已解决", "暂无法解决"],
      ListData: [],
      listLoading: false,
      drawerVisible: false,
      titName: "",
      recordId: null,
      userList: [],
    };
  },
  created() {
    if (this.$route.query.type == "add") {
      this.drawerVisible = true;
      this.titName = "反馈查重问题";
    }
    this.loadList();
    this.loadUserBase();
  },
  methods: {
    //获取用户列表
    loadUserBase() {
      //获取用户列表
      getUserBaseInfos().then((res) => {
        if (res.success) {
          this.userList = res.result;
        }
      });
    },
    //选择时间
    getTime(val) {
      this.timeVal = val;
      if (val) {
        this.filter.queryTimeStart = val[0];
        this.filter.queryTimeEnd = val[1];
      } else {
        this.filter.queryTimeStart = "";
        this.filter.queryTimeEnd = "";
      }
      this.filter.page.current = 1;
      this.loadList();
    },
    getData() {
      this.filter.page.current = 1;
      this.loadList();
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadList();
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.filter.page.current = val;
      this.loadList();
    },
    //获取列表
    loadList() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      resumeRepeatRecordPage(this.filter).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          //   for (var i = 0; i < that.ListData.length; i++) {
          //     that.ListData[i].preVeiwList = [];
          //     for (var l = 0; l < that.ListData[i].imgDesc.length; l++) {
          //       that.ListData[i].preVeiwList.push(
          //         that.ListData[i].imgDesc[l].fileUrl
          //       );
          //     }
          //   }
          //   console.log(that.ListData);
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //编辑
    handlerEdit(row) {
      this.drawerVisible = true;
      this.titName = "反馈查重问题";
      this.recordId = row.recordId;
    },
    //进度跟踪
    progressTracking(row) {
      this.drawerVisible = true;
      this.titName = "进度跟踪";
      this.recordId = row.recordId;
    },
    handleDown(row) {
      //下载文件
      //   location.href =
      //     api.ApiBaseUrl +
      //     "/api/file/download?fileName=" +
      //     encodeURIComponent(row.fileName) +
      //     "&fileUrl=" +
      //     row.fileUrl;
      location.href =
        api.ApiBaseUrl +
        "/api/file/download?fileName=" +
        encodeURIComponent(row.fileName) +
        "&filePath=" +
        row.fileUrl;
    },
    //预览
    previewOpen(item) {
      // let item = this.versionVal;
      console.log(
        "https://view.officeapps.live.com/op/view.aspx?src=" +
          api.PreviewUrl +
          item.fileUrl
      );
      const fileType =
        item.fileName.split(".")[item.fileName.split(".").length - 1];
      if (
        fileType == "doc" ||
        fileType == "docx" ||
        fileType == "xlsx" ||
        fileType == "pptx" ||
        fileType == "ppt"
      ) {
        //wps预览
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" +
            api.PreviewUrl +
            item.fileUrl,
          "_blank"
        );
      } else if (fileType == "pdf") {
        //pdf预览
        window.open(
          "/pdf/web/viewer.html?file=" + api.PreviewUrl + item.fileUrl,
          "_blank"
        );
      } else if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
        //图片预览
        window.open(api.PreviewUrl + item.fileUrl, "_blank");
      } else if (fileType) {
        this.$message.error("不支持预览");
      }
    },
    //关闭弹窗
    handleClose() {
      this.drawerVisible = false;
      this.loadList();
    },
  },
};
</script>

<style lang='scss' scoped>
.searchM {
  margin-bottom: 5px;
  .el-form-item {
    float: left;
    margin-bottom: 15px;
  }
}
</style>
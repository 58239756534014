<template>
  <section class="feedbackInfo">
    <div class="drawerCon_from">
      <el-form
        @submit.native.prevent
        @submit.enter.prevent
        :model="feedbackInfo"
        ref="feedbackInfo"
        :validate-on-rule-change="false"
        :rules="rulesList"
        label-width="250px"
        v-loading="loading"
      >
        <el-row :gutter="20">
          <el-col :span="22">
            <el-form-item label="问题截图（截全屏）：" class="is-required">
              <tinymce
                v-model="feedbackInfo.imgDesc"
                :height="450"
                @annexList="annexList"
                :ishowImage="true"
                :onlyImage="true"
                placeholder="请将问题截图直接粘贴在此处，可粘贴多张"
                ref="removeAnList"
                style="width: calc(100% - 4px)"
              ></tinymce>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="问题描述：" prop="textDesc">
              <el-input
                placeholder
                maxlength="500"
                v-model="feedbackInfo.textDesc"
                show-word-limit
                :autosize="{ minRows: 2, maxRows: 8 }"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item
              label="云虰的重复简历ID（或联系方式）："
              prop="idOrTel"
            >
              <el-input
                placeholder
                maxlength="50"
                v-model="feedbackInfo.idOrTel"
                :autosize="{ minRows: 2, maxRows: 8 }"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="上传附件：" prop="fileList">
              <el-upload
                ref="upload"
                class="upload-demo"
                drag
                multiple
                :headers="myHeaders"
                :action="actionUrl"
                :before-upload="beforeUpload"
                :on-success="handleInfoSuccess"
                :on-error="handleInfoError"
                :on-exceed="handleInfoChange"
                :file-list="fileInfoList"
                :on-remove="handleRemove"
                accept="docx,doc,xlsx,xls,ppt,pptx,pdf,jpg,jpeg,png"
                :limit="20"
                style="width: 100%"
              >
                <i class="el-icon-upload" style="margin-top: 50px"></i>
                <div class="el-upload__text" style="line-height: 1">
                  将文件拖到此处，或
                  <span class="tabHref">点击上传</span>
                </div>
                <div
                  slot="tip"
                  class="el-upload__tip"
                  style="margin: 0; line-height: 1"
                >
                  如果是猎聘简历，请在猎聘把简历转发给董圣旖
                  ；如果是非猎聘简历，请在此处上传简历附件（或企业微信联系董圣旖）
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="drawerCon_footer fixedFoot">
      <el-button type="default" plain size="mini" @click="close()"
        >取消</el-button
      >
      <el-button
        type="primary"
        size="mini"
        :loading="saveloading"
        @click="saveInfos('feedbackInfo')"
        style="margin-right: 15px"
        >保存</el-button
      >
    </div>
  </section>
</template>

<script>
import Cookies from "js-cookie";
import { ApiBaseUrl } from "../../api/http";
import Tinymce from "@/components/Tinymce";
import {
  resumeRepeatRecordFeedbackRepeat, //新增反馈查重问题
  resumeRepeatRecordEdit, //编辑反馈查重问题
  resumeRepeatRecordEditDetail, //此查重问题编辑详情
} from "../../api/api";
export default {
  components: {
    Tinymce,
  },
  props: ["recordId"],
  data() {
    return {
      feedbackInfo: {
        attachments: [],
        imgDesc: "",
      },
      rulesList: {
        textDesc: [{ required: true, message: "请选择", trigger: "change" }],
        idOrTel: [{ required: true, message: "请选择", trigger: "change" }],
      },
      loading: false,
      actionUrl: ApiBaseUrl + "/api/file/uploadOne",
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      saveloading: false,
      fileInfoList: [],
    };
  },
  created() {
    if (this.recordId) {
      this.getDetail();
    }
  },
  methods: {
    //获取详情
    getDetail() {
      resumeRepeatRecordEditDetail({ recordId: this.recordId }).then((res) => {
        if (res.success) {
          this.feedbackInfo = res.result;
          for (let i in res.result.attachments) {
            this.fileInfoList.push({
              name: res.result.attachments[i].fileName,
              url: res.result.attachments[i].fileUrl,
            });
          }
        }
      });
    },
    annexList(arr) {
      console.log(arr);
      this.annexListdata = arr;
    },
    beforeUpload: function (file) {
      const isLt = file.size / 1024 / 1024 < 20;
      if (!isLt) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt;
    },
    handleRemove(file, fileList) {
      this.feedbackInfo.attachments = [];
      fileList.forEach((item) => {
        this.feedbackInfo.attachments.push({
          fileUrl: item.response.result,
          fileName: item.name,
          fileType: item.raw.type,
        });
      });
    },
    handleInfoChange(files, fileList) {
      this.$message.error("最多上传20份文件");
    },
    handleInfoSuccess(res, file, fileList) {
      if (fileList && fileList.length > 0) {
        this.$refs["feedbackInfo"].clearValidate(["attachments"]); // 去掉file验证
      }
      this.fileInfoList = fileList;
      this.feedbackInfo.attachments = [];
      fileList.forEach((item) => {
        this.feedbackInfo.attachments.push({
          fileUrl: item.response ? item.response.result : "",
          fileName: item.name,
          fileType: item.raw.type,
        });
      });
    },
    handleInfoError(err) {
      this.$message.error("文件上传出错");
    },
    close() {
      this.$emit("close");
    },
    //保存
    saveInfos(formName) {
      return new Promise((resole) => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (
              this.feedbackInfo.imgDesc == "" ||
              this.feedbackInfo.imgDesc == null
            ) {
              this.$message.error("请粘贴问题截图（截全屏）");
              return false;
            }
            let announcement = null;
            if (this.recordId) {
              this.feedbackInfo.recordId = this.recordId;
              announcement = resumeRepeatRecordEdit;
            } else {
              announcement = resumeRepeatRecordFeedbackRepeat;
            }
            announcement(this.feedbackInfo).then((res) => {
              if (res.success) {
                this.$message.success("成功");
                this.$emit("close");
              }
              resole();
            });
          }
        });
      });
    },
  },
};
</script>

<style lang='scss'>
.feedbackInfo {
  .drawerCon_from {
    border: 0 !important;
  }
  .mce-first {
    height: 35px !important;
  }
  .el-upload,
  .el-upload-dragger {
    width: 100% !important;
  }
}
</style>
<template>
  <section class="progressTracking">
    <div class="drawerCon_from">
      <el-form
        @submit.native.prevent
        @submit.enter.prevent
        :model="progressTracking"
        ref="progressTracking"
        :validate-on-rule-change="false"
        label-width="140px"
        v-loading="loading"
      >
        <el-row :gutter="20">
          <el-col :span="22">
            <el-form-item label="状态：">
              <el-radio
                class="radioBtn"
                style="margin-right: 10px"
                v-model="progressTracking.status"
                label="技术定位中"
                @click.native.prevent="radioClick('技术定位中')"
              ></el-radio>
              <el-radio
                class="radioBtn"
                v-model="progressTracking.status"
                label="解决中"
                @click.native.prevent="radioClick('解决中')"
              ></el-radio>
              <el-radio
                class="radioBtn"
                v-model="progressTracking.status"
                label="已解决"
                @click.native.prevent="radioClick('已解决')"
              ></el-radio>
              <el-radio
                class="radioBtn"
                v-model="progressTracking.status"
                label="暂无法解决"
                @click.native.prevent="radioClick('暂无法解决')"
              ></el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="问题归属：">
              <el-checkbox-group v-model="progressTracking.belongTo">
                <el-checkbox label="云虰"></el-checkbox>
                <el-checkbox label="倍罗"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="简历渠道：">
              <el-select
                clearable
                v-model="progressTracking.channel"
                style="width: 100%"
              >
                <el-option
                  v-for="(i, index) in channelList"
                  :key="'cha' + index"
                  :label="i.value"
                  :value="i.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="问题产生原因：">
              <el-input
                placeholder
                maxlength="500"
                v-model="progressTracking.reason"
                show-word-limit
                :autosize="{ minRows: 2, maxRows: 8 }"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="解决计划：">
              <el-input
                placeholder="描述尽量精简"
                maxlength="500"
                v-model="progressTracking.resolvePlan"
                show-word-limit
                :autosize="{ minRows: 2, maxRows: 8 }"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="drawerCon_footer fixedFoot">
      <el-button type="default" plain size="mini" @click="close()"
        >取消</el-button
      >
      <el-button
        type="primary"
        size="mini"
        :loading="saveloading"
        @click="saveInfos('progressTracking')"
        style="margin-right: 15px"
        >保存</el-button
      >
    </div>
  </section>
</template>

<script>
import * as data from "./../../libs/data.json";
import {
  resumeRepeatRecordFeedbackTrack, //进度跟踪
  resumeRepeatRecordLastFeedbackInfo, //最近一条进度跟踪详情
} from "../../api/api";
export default {
  props: ["recordId"],
  data() {
    return {
      progressTracking: {
        channel: "",
        reason: "",
        recordId: "",
        resolvePlan: "",
        status: "",
        belongTo: [],
      },
      loading: false,
      saveloading: false,
      channelList: data.selectChannelList,
    };
  },
  created() {
    if (this.recordId) {
      this.getDetail();
    }
  },
  methods: {
    //获取详情
    getDetail() {
      resumeRepeatRecordLastFeedbackInfo({ recordId: this.recordId }).then(
        (res) => {
          if (res.success) {
            this.progressTracking = res.result;
          }
        }
      );
    },
    //单选取消
    radioClick(type) {
      this.progressTracking.status =
        this.progressTracking.status == type ? "" : type;
    },
    close() {
      this.$emit("close");
    },
    //保存
    saveInfos() {
      if (this.recordId) {
        this.progressTracking.recordId = this.recordId;
      }
      resumeRepeatRecordFeedbackTrack(this.progressTracking).then((res) => {
        if (res.success) {
          this.$message.success("成功");
          this.$emit("close");
        }
      });
    },
  },
};
</script>

<style lang='scss' scope>
.progressTracking {
  // margin: 20px 0;
  // padding: 0 0 50px;
  .drawerCon_from {
    border: 0 !important;
    padding: 0px 0 100px;
  }
  .radioBtn {
    .el-radio__input {
      .el-radio__inner {
        box-shadow: none !important;
      }
    }
  }
  .el-textarea .el-input__count {
    background: transparent;
  }
}
</style>